<template>
  <div class="about">
    <van-tabs>
      <van-tab title="第一周">
        <img class="back" src="~@/assets/images/返回.png" alt="" width="20px" height="18px"  @click="back">
        <div class="about-container">
          <div class="container-title">
          <img src="~@/assets/images/container-pic.png" alt="滑动"> 
          <span>左滑查看更多</span>
          </div>
          <div class="app-container">
            <img class="tab-pic" src="~@/assets/images/tab-pic.png" alt="直播人数榜">
            <div class="container-top">
              <ul>
              <li class="ul-chidern1">
                <div class="ul-chidern1-conent">
                  <img :src="items[1].avatar" alt="">
                  <p>{{items[1].user_nicename}}</p>
                </div>
                <span>购买量：{{items[1].total}}</span>
              </li>
              <li class="ul-chidern2">
                <div class="ul-chidern2-conent">
                  <img :src="items[0].avatar" alt="">
                  <p>{{items[0].user_nicename}}</p>
                </div>
                <span>购买量：{{items[0].total}}</span>
              </li>
              <li class="ul-chidern3">
                <div class="ul-chidern3-conent">
                  <img :src="items[2].avatar" alt="">
                  <p>{{items[2].user_nicename}}</p>
                </div>
                <span>购买量：{{items[2].total}}</span>
              </li>
            </ul>
            </div>
            <div class="container-center">
              <ul>
                <li>排名</li>
                <li>头像</li>
                <li>主播名称</li>
                <li>观看量</li>
              </ul>
            </div>
            <div class="container-bottom">
              <ul>
              <li v-for="(item,index) in items.slice(3)" :key="item.ranking" >
                <span>{{index+4}}</span>
                <span class="conainer-bottom-pic">
                  <img :src="item.avatar" alt='' />
                </span>
                <span>{{ item.user_nicename }}</span>
                <span>{{ item.total }}</span>
              </li>
            </ul>
            </div>
          </div>
        </div>
      </van-tab>
      <van-tab title="第二周">
        <img class="back" src="~@/assets/images/返回.png" alt="" width="20px" height="18px"  @click="back">
        <div class="container-title">
          <img src="~@/assets/images/container-pic.png" alt="滑动"> 
          <span>左滑查看更多</span>
        </div>
        <div class="app-container">
          <img class="tab-pic" src="~@/assets/images/tab-pic.png" alt="直播人数榜">
          <div class="container-top">
            <ul>
              <li class="ul-chidern1">
                <div class="ul-chidern1-conent">
                  <img :src="messages[1].avatar" alt="">
                  <p>{{messages[1].user_nicename}}</p>
                </div>
                <span>购买量：{{messages[1].total}}</span>
              </li>
              <li class="ul-chidern2">
                <div class="ul-chidern2-conent">
                  <img :src="messages[0].avatar" alt="">
                  <p>{{messages[0].user_nicename}}</p>
                </div>
                <span>购买量：{{messages[0].total}}</span>
              </li>
              <li class="ul-chidern3">
                <div class="ul-chidern3-conent">
                  <img :src="messages[2].avatar" alt="">
                  <p>{{messages[2].user_nicename}}</p>
                </div>
                <span>购买量：{{messages[2].total}}</span>
              </li>
            </ul>
          </div>
          <div class="container-center">
            <ul>
              <li>排名</li>
              <li>头像</li>
              <li>主播名称</li>
              <li>观看量</li>
            </ul>
          </div>
          <div class="container-bottom">
            <ul>
              <li v-for="(item,index) in messages.slice(3)" :key="item.ranking" >
                <span>{{index+4}}</span>
                <span class="conainer-bottom-pic">
                  <img :src="item.avatar" alt='' />
                </span>
                <span>{{ item.user_nicename }}</span>
                <span>{{ item.total }}</span>
              </li>
            </ul>
          </div>
        </div>
      </van-tab>
      <van-tab title="第三周">
        <img class="back" src="~@/assets/images/返回.png" alt="" width="20px" height="18px"  @click="back">
        <div class="container-title">
          <img src="~@/assets/images/container-pic.png" alt="滑动"> 
          <span>左滑查看更多</span>
        </div>
        <div class="app-container">
          <img class="tab-pic" src="~@/assets/images/tab-pic.png" alt="直播人数榜">
          <div class="container-top">
            <ul>
              <li class="ul-chidern1">
                <div class="ul-chidern1-conent">
                  <img :src="messages1[1].avatar" alt="">
                  <p>{{messages1[1].user_nicename}}</p>
                </div>
                <span>购买量：{{messages1[1].total}}</span>
              </li>
              <li class="ul-chidern2">
                <div class="ul-chidern2-conent">
                  <img :src="messages1[0].avatar" alt="">
                  <p>{{messages1[0].user_nicename}}</p>
                </div>
                <span>购买量：{{messages1[0].total}}</span>
              </li>
              <li class="ul-chidern3">
                <div class="ul-chidern3-conent">
                  <img :src="messages1[2].avatar" alt="">
                  <p>{{messages1[2].user_nicename}}</p>
                </div>
                <span>购买量：{{messages1[2].total}}</span>
              </li>
            </ul>
          </div>
          <div class="container-center">
            <ul>
              <li>排名</li>
              <li>头像</li>
              <li>主播名称</li>
              <li>观看量</li>
            </ul>
          </div>
          <div class="container-bottom">
            <ul>
              <li v-for="(item,index) in messages1.slice(3)" :key="item.ranking" >
                <span>{{index+4}}</span>
                <span class="conainer-bottom-pic">
                  <img :src="item.avatar" alt='' />
                </span>
                <span>{{ item.user_nicename }}</span>
                <span>{{ item.total }}</span>
              </li>
            </ul>
          </div>
        </div>
      </van-tab>
      <van-tab title="第四周">
        <img class="back" src="~@/assets/images/返回.png" alt="" width="20px" height="18px"  @click="back">
        <div class="container-title">
          <img src="~@/assets/images/container-pic.png" alt="滑动"> 
          <span>左滑查看更多</span>
        </div>
        <div class="app-container">
          <img class="tab-pic" src="~@/assets/images/tab-pic.png" alt="直播人数榜">
          <div class="container-top">
            <ul>
              <li class="ul-chidern1">
                <div class="ul-chidern1-conent">
                  <img :src="messages2[1].avatar" alt="">
                  <p>{{messages2[1].user_nicename}}</p>
                </div>
                <span>购买量：{{messages2[1].total}}</span>
              </li>
              <li class="ul-chidern2">
                <div class="ul-chidern2-conent">
                  <img :src="messages2[0].avatar" alt="">
                  <p>{{messages2[0].user_nicename}}</p>
                </div>
                <span>购买量：{{messages2[0].total}}</span>
              </li>
              <li class="ul-chidern3">
                <div class="ul-chidern3-conent">
                  <img :src="messages2[2].avatar" alt="">
                  <p>{{messages2[2].user_nicename}}</p>
                </div>
                <span>购买量：{{messages2[2].total}}</span>
              </li>
            </ul>
          </div>
          <div class="container-center">
            <ul>
              <li>排名</li>
              <li>头像</li>
              <li>主播名称</li>
              <li>观看量</li>
            </ul>
          </div>
          <div class="container-bottom">
            <ul>
              <li v-for="(item,index) in messages2.slice(3)" :key="item.ranking" >
                <span>{{index+4}}</span>
                <span class="conainer-bottom-pic">
                  <img :src="item.avatar" alt='' />
                </span>
                <span>{{ item.user_nicename }}</span>
                <span>{{ item.total }}</span>
              </li>
            </ul>
          </div>
        </div>
      </van-tab>
      <van-tab title="第五周">
        <img class="back" src="~@/assets/images/返回.png" alt="" width="20px" height="18px"  @click="back">
        <div class="container-title">
          <img src="~@/assets/images/container-pic.png" alt="滑动"> 
          <span>左滑查看更多</span>
        </div>
        <div class="app-container">
          <img class="tab-pic" src="~@/assets/images/tab-pic.png" alt="直播人数榜">
          <div class="container-top">
            <ul>
              <li class="ul-chidern1">
                <div class="ul-chidern1-conent">
                  <img :src="messages3[1].avatar" alt="">
                  <p>{{messages3[1].user_nicename}}</p>
                </div>
                <span>购买量：{{messages3[1].total}}</span>
              </li>
              <li class="ul-chidern2">
                <div class="ul-chidern2-conent">
                  <img :src="messages3[0].avatar" alt="">
                  <p>{{messages3[0].user_nicename}}</p>
                </div>
                <span>购买量：{{messages3[0].total}}</span>
              </li>
              <li class="ul-chidern3">
                <div class="ul-chidern3-conent">
                  <img :src="messages3[2].avatar" alt="">
                  <p>{{messages3[2].user_nicename}}</p>
                </div>
                <span>购买量：{{messages3[2].total}}</span>
              </li>
            </ul>
          </div>
          <div class="container-center">
            <ul>
              <li>排名</li>
              <li>头像</li>
              <li>主播名称</li>
              <li>观看量</li>
            </ul>
          </div>
          <div class="container-bottom">
            <ul>
              <li v-for="(item,index) in messages3.slice(3)" :key="item.ranking" >
                <span>{{index+4}}</span>
                <span class="conainer-bottom-pic">
                  <img :src="item.avatar" alt='' />
                </span>
                <span>{{ item.user_nicename }}</span>
                <span>{{ item.total }}</span>
              </li>
            </ul>
          </div>
        </div>
      </van-tab>
      <van-tab title="第六周">
        <!-- <img class="back" src="~@/assets/images/返回.png" alt="" width="20px" height="18px"  @click="back"> -->
        <div class="container-title">
          <img src="~@/assets/images/container-pic.png" alt="滑动"> 
          <span>左滑查看更多</span>
        </div>
        <div class="app-container">
          <img class="tab-pic" src="~@/assets/images/tab-pic.png" alt="直播人数榜">
          <div class="container-top">
            <ul>
              <li class="ul-chidern1">
                <div class="ul-chidern1-conent">
                  <img :src="messages4[1].avatar" alt="">
                  <p>{{messages4[1].user_nicename}}</p>
                </div>
                <span>购买量：{{messages4[1].total}}</span>
              </li>
              <li class="ul-chidern2">
                <div class="ul-chidern2-conent">
                  <img :src="messages4[0].avatar" alt="">
                  <p>{{messages4[0].user_nicename}}</p>
                </div>
                <span>购买量：{{messages4[0].total}}</span>
              </li>
              <li class="ul-chidern3">
                <div class="ul-chidern3-conent">
                  <img :src="messages4[2].avatar" alt="">
                  <p>{{messages4[2].user_nicename}}</p>
                </div>
                <span>购买量：{{messages4[2].total}}</span>
              </li>
            </ul>
          </div>
          <div class="container-center">
            <ul>
              <li>排名</li>
              <li>头像</li>
              <li>主播名称</li>
              <li>购买量</li>
            </ul>
          </div>
          <div class="container-bottom">
            <ul>
              <li v-for="(item,index) in messages4.slice(3)" :key="item.ranking" >
                <span>{{index + 4}}</span>
                <span class="conainer-bottom-pic">
                  <img :src="item.avatar" alt='' />
                </span>
                <span>{{ item.user_nicename }}</span>
                <span>{{ item.total }}</span>
              </li>
            </ul>
          </div>
        </div>
      </van-tab>
      <van-tab title="第七周">
        <div class="container-title">
          <img src="~@/assets/images/container-pic.png" alt="滑动"> 
          <span>左滑查看更多</span>
        </div>
        <div class="app-container">
          <img class="tab-pic" src="~@/assets/images/tab-pic.png" alt="直播人数榜">
          <div class="container-top">
            <ul>
              <li class="ul-chidern1">
                <div class="ul-chidern1-conent">
                  <img :src="messages5[1].avatar" alt="">
                  <p>{{messages5[1].user_nicename}}</p>
                </div>
                <span>购买量：{{messages5[1].total}}</span>
              </li>
              <li class="ul-chidern2">
                <div class="ul-chidern2-conent">
                  <img :src="messages5[0].avatar" alt="">
                  <p>{{messages5[0].user_nicename}}</p>
                </div>
                <span>购买量：{{messages5[0].total}}</span>
              </li>
              <li class="ul-chidern3">
                <div class="ul-chidern3-conent">
                  <img :src="messages5[2].avatar" alt="">
                  <p>{{messages5[2].user_nicename}}</p>
                </div>
                <span>购买量：{{messages5[2].total}}</span>
              </li>
            </ul>
          </div>
          <div class="container-center">
            <ul>
              <li>排名</li>
              <li>头像</li>
              <li>主播名称</li>
              <li>购买量</li>
            </ul>
          </div>
          <div class="container-bottom">
            <ul>
              <li v-for="(item,index) in messages5.slice(3)" :key="item.ranking" >
                <span>{{index + 4}}</span>
                <span class="conainer-bottom-pic">
                  <img :src="item.avatar" alt='' />
                </span>
                <span>{{ item.user_nicename }}</span>
                <span>{{ item.total }}</span>
              </li>
            </ul>
          </div>
        </div>
      </van-tab>
      <van-tab title="第八周">
        <div class="container-title">
          <img src="~@/assets/images/container-pic.png" alt="滑动"> 
          <span>左滑查看更多</span>
        </div>
        <div class="app-container">
          <img class="tab-pic" src="~@/assets/images/tab-pic.png" alt="直播人数榜">
          <div class="container-top">
            <ul>
              <li class="ul-chidern1">
                <div class="ul-chidern1-conent">
                  <img :src="messages6[1].avatar" alt="">
                  <p>{{messages6[1].user_nicename}}</p>
                </div>
                <span>购买量：{{messages6[1].total}}</span>
              </li>
              <li class="ul-chidern2">
                <div class="ul-chidern2-conent">
                  <img :src="messages6[0].avatar" alt="">
                  <p>{{messages6[0].user_nicename}}</p>
                </div>
                <span>购买量：{{messages6[0].total}}</span>
              </li>
              <li class="ul-chidern3">
                <div class="ul-chidern3-conent">
                  <img :src="messages6[2].avatar" alt="">
                  <p>{{messages6[2].user_nicename}}</p>
                </div>
                <span>购买量：{{messages6[2].total}}</span>
              </li>
            </ul>
          </div>
          <div class="container-center">
            <ul>
              <li>排名</li>
              <li>头像</li>
              <li>主播名称</li>
              <li>购买量</li>
            </ul>
          </div>
          <div class="container-bottom">
            <ul>
              <li v-for="(item,index) in messages6.slice(3)" :key="item.ranking" >
                <span>{{index + 4}}</span>
                <span class="conainer-bottom-pic">
                  <img :src="item.avatar" alt='' />
                </span>
                <span>{{ item.user_nicename }}</span>
                <span>{{ item.total }}</span>
              </li>
            </ul>
          </div>
        </div>
        
      </van-tab>
      <van-tab title="第九周">
        <div class="container-title">
          <img src="~@/assets/images/container-pic.png" alt="滑动"> 
          <span>左滑查看更多</span>
        </div>
        <div class="app-container">
          <img class="tab-pic" src="~@/assets/images/tab-pic.png" alt="直播人数榜">
          <div class="container-top">
            <ul>
              <li class="ul-chidern1">
                <div class="ul-chidern1-conent">
                  <img :src="messages7[1].avatar" alt="">
                  <p>{{messages7[1].user_nicename}}</p>
                </div>
                <span>购买量：{{messages7[1].total}}</span>
              </li>
              <li class="ul-chidern2">
                <div class="ul-chidern2-conent">
                  <img :src="messages7[0].avatar" alt="">
                  <p>{{messages7[0].user_nicename}}</p>
                </div>
                <span>购买量：{{messages7[0].total}}</span>
              </li>
              <li class="ul-chidern3">
                <div class="ul-chidern3-conent">
                  <img :src="messages7[2].avatar" alt="">
                  <p>{{messages7[2].user_nicename}}</p>
                </div>
                <span>购买量：{{messages7[2].total}}</span>
              </li>
            </ul>
          </div>
          <div class="container-center">
            <ul>
              <li>排名</li>
              <li>头像</li>
              <li>主播名称</li>
              <li>购买量</li>
            </ul>
          </div>
          <div class="container-bottom">
            <ul>
              <li v-for="(item,index) in messages7.slice(3)" :key="item.ranking" >
                <span>{{index + 4}}</span>
                <span class="conainer-bottom-pic">
                  <img :src="item.avatar" alt='' />
                </span>
                <span>{{ item.user_nicename }}</span>
                <span>{{ item.total }}</span>
              </li>
            </ul>
          </div>
        </div>
        
      </van-tab>
      <van-tab title="第十周">
        <div class="container-title">
          <img src="~@/assets/images/container-pic.png" alt="滑动"> 
          <span>左滑查看更多</span>
        </div>
        <div class="app-container">
          <img class="tab-pic" src="~@/assets/images/tab-pic.png" alt="直播人数榜">
          <div class="container-top">
            <ul>
              <li class="ul-chidern1">
                <div class="ul-chidern1-conent">
                  <img :src="messages8[1].avatar" alt="">
                  <p>{{messages8[1].user_nicename}}</p>
                </div>
                <span>购买量：{{messages8[1].total}}</span>
              </li>
              <li class="ul-chidern2">
                <div class="ul-chidern2-conent">
                  <img :src="messages8[0].avatar" alt="">
                  <p>{{messages8[0].user_nicename}}</p>
                </div>
                <span>购买量：{{messages8[0].total}}</span>
              </li>
              <li class="ul-chidern3">
                <div class="ul-chidern3-conent">
                  <img :src="messages8[2].avatar" alt="">
                  <p>{{messages8[2].user_nicename}}</p>
                </div>
                <span>购买量：{{messages8[2].total}}</span>
              </li>
            </ul>
          </div>
          <div class="container-center">
            <ul>
              <li>排名</li>
              <li>头像</li>
              <li>主播名称</li>
              <li>购买量</li>
            </ul>
          </div>
          <div class="container-bottom">
            <ul>
              <li v-for="(item,index) in messages8.slice(3)" :key="item.ranking" >
                <span>{{index + 4}}</span>
                <span class="conainer-bottom-pic">
                  <img :src="item.avatar" alt='' />
                </span>
                <span>{{ item.user_nicename }}</span>
                <span>{{ item.total }}</span>
              </li>
            </ul>
          </div>
        </div>
        
      </van-tab>
      <van-tab title="第十一周">
        <div class="container-title">
          <img src="~@/assets/images/container-pic.png" alt="滑动"> 
          <span>左滑查看更多</span>
        </div>
        <div class="app-container">
          <img class="tab-pic" src="~@/assets/images/tab-pic.png" alt="直播人数榜">
          <div class="container-top">
            <ul>
              <li class="ul-chidern1">
                <div class="ul-chidern1-conent">
                  <img :src="messages9[1].avatar" alt="">
                  <p>{{messages9[1].user_nicename}}</p>
                </div>
                <span>购买量：{{messages9[1].total}}</span>
              </li>
              <li class="ul-chidern2">
                <div class="ul-chidern2-conent">
                  <img :src="messages9[0].avatar" alt="">
                  <p>{{messages9[0].user_nicename}}</p>
                </div>
                <span>购买量：{{messages9[0].total}}</span>
              </li>
              <li class="ul-chidern3">
                <div class="ul-chidern3-conent">
                  <img :src="messages9[2].avatar" alt="">
                  <p>{{messages9[2].user_nicename}}</p>
                </div>
                <span>购买量：{{messages9[2].total}}</span>
              </li>
            </ul>
          </div>
          <div class="container-center">
            <ul>
              <li>排名</li>
              <li>头像</li>
              <li>主播名称</li>
              <li>购买量</li>
            </ul>
          </div>
          <div class="container-bottom">
            <ul>
              <li v-for="(item,index) in messages9.slice(3)" :key="item.ranking" >
                <span>{{index + 4}}</span>
                <span class="conainer-bottom-pic">
                  <img :src="item.avatar" alt='' />
                </span>
                <span>{{ item.user_nicename }}</span>
                <span>{{ item.total }}</span>
              </li>
            </ul>
          </div>
        </div>
        
      </van-tab>
      <van-tab title="第十二周">
        <div class="container-title">
          <img src="~@/assets/images/container-pic.png" alt="滑动"> 
          <span>左滑查看更多</span>
        </div>
        <div class="app-container">
          <img class="tab-pic" src="~@/assets/images/tab-pic.png" alt="直播人数榜">
          <div class="container-top">
            <ul>
              <li class="ul-chidern1">
                <div class="ul-chidern1-conent">
                  <img :src="messages10[1].avatar" alt="">
                  <p>{{messages10[1].user_nicename}}</p>
                </div>
                <span>购买量：{{messages10[1].total}}</span>
              </li>
              <li class="ul-chidern2">
                <div class="ul-chidern2-conent">
                  <img :src="messages10[0].avatar" alt="">
                  <p>{{messages10[0].user_nicename}}</p>
                </div>
                <span>购买量：{{messages10[0].total}}</span>
              </li>
              <li class="ul-chidern3">
                <div class="ul-chidern3-conent">
                  <img :src="messages10[2].avatar" alt="">
                  <p>{{messages10[2].user_nicename}}</p>
                </div>
                <span>购买量：{{messages10[2].total}}</span>
              </li>
            </ul>
          </div>
          <div class="container-center">
            <ul>
              <li>排名</li>
              <li>头像</li>
              <li>主播名称</li>
              <li>购买量</li>
            </ul>
          </div>
          <div class="container-bottom">
            <ul>
              <li v-for="(item,index) in messages10.slice(3)" :key="item.ranking" >
                <span>{{index + 4}}</span>
                <span class="conainer-bottom-pic">
                  <img :src="item.avatar" alt='' />
                </span>
                <span>{{ item.user_nicename }}</span>
                <span>{{ item.total }}</span>
              </li>
            </ul>
          </div>
        </div>
        
      </van-tab>
      <van-tab title="第十三周">
        <div class="container-title">
          <img src="~@/assets/images/container-pic.png" alt="滑动"> 
          <span>左滑查看更多</span>
        </div>
        <div class="app-container">
          <img class="tab-pic" src="~@/assets/images/tab-pic.png" alt="直播人数榜">
          <div class="container-top">
            <ul>
              <li class="ul-chidern1">
                <div class="ul-chidern1-conent">
                  <img :src="messages11[1].avatar" alt="">
                  <p>{{messages11[1].user_nicename}}</p>
                </div>
                <span>购买量：{{messages11[1].total}}</span>
              </li>
              <li class="ul-chidern2">
                <div class="ul-chidern2-conent">
                  <img :src="messages11[0].avatar" alt="">
                  <p>{{messages11[0].user_nicename}}</p>
                </div>
                <span>购买量：{{messages11[0].total}}</span>
              </li>
              <li class="ul-chidern3">
                <div class="ul-chidern3-conent">
                  <img :src="messages11[2].avatar" alt="">
                  <p>{{messages11[2].user_nicename}}</p>
                </div>
                <span>购买量：{{messages11[2].total}}</span>
              </li>
            </ul>
          </div>
          <div class="container-center">
            <ul>
              <li>排名</li>
              <li>头像</li>
              <li>主播名称</li>
              <li>购买量</li>
            </ul>
          </div>
          <div class="container-bottom">
            <ul>
              <li v-for="(item,index) in messages11.slice(3)" :key="item.ranking" >
                <span>{{index + 4}}</span>
                <span class="conainer-bottom-pic">
                  <img :src="item.avatar" alt='' />
                </span>
                <span>{{ item.user_nicename }}</span>
                <span>{{ item.total }}</span>
              </li>
            </ul>
          </div>
        </div>
        
      </van-tab>
      <van-tab title="第十四周">
        <div class="container-title">
          <img src="~@/assets/images/container-pic.png" alt="滑动"> 
          <span>左滑查看更多</span>
        </div>
        <div class="app-container">
          <img class="tab-pic" src="~@/assets/images/tab-pic.png" alt="直播人数榜">
          <div class="container-top">
            <ul>
              <li class="ul-chidern1">
                <div class="ul-chidern1-conent">
                  <img :src="messages12[1].avatar" alt="">
                  <p>{{messages12[1].user_nicename}}</p>
                </div>
                <span>购买量：{{messages12[1].total}}</span>
              </li>
              <li class="ul-chidern2">
                <div class="ul-chidern2-conent">
                  <img :src="messages12[0].avatar" alt="">
                  <p>{{messages12[0].user_nicename}}</p>
                </div>
                <span>购买量：{{messages12[0].total}}</span>
              </li>
              <li class="ul-chidern3">
                <div class="ul-chidern3-conent">
                  <img :src="messages12[2].avatar" alt="">
                  <p>{{messages12[2].user_nicename}}</p>
                </div>
                <span>购买量：{{messages12[2].total}}</span>
              </li>
            </ul>
          </div>
          <div class="container-center">
            <ul>
              <li>排名</li>
              <li>头像</li>
              <li>主播名称</li>
              <li>购买量</li>
            </ul>
          </div>
          <div class="container-bottom">
            <ul>
              <li v-for="(item,index) in messages12.slice(3)" :key="item.ranking" >
                <span>{{index + 4}}</span>
                <span class="conainer-bottom-pic">
                  <img :src="item.avatar" alt='' />
                </span>
                <span>{{ item.user_nicename }}</span>
                <span>{{ item.total }}</span>
              </li>
            </ul>
          </div>
        </div>
        
      </van-tab>
      <van-tab title="第十五周">
        <div class="container-title">
          <img src="~@/assets/images/container-pic.png" alt="滑动"> 
          <span>左滑查看更多</span>
        </div>
        <div class="app-container">
          <img class="tab-pic" src="~@/assets/images/tab-pic.png" alt="直播人数榜">
          <div class="container-top">
            <ul>
              <li class="ul-chidern1">
                <div class="ul-chidern1-conent">
                  <img :src="messages13[1].avatar" alt="">
                  <p>{{messages13[1].user_nicename}}</p>
                </div>
                <span>购买量：{{messages13[1].total}}</span>
              </li>
              <li class="ul-chidern2">
                <div class="ul-chidern2-conent">
                  <img :src="messages13[0].avatar" alt="">
                  <p>{{messages13[0].user_nicename}}</p>
                </div>
                <span>购买量：{{messages13[0].total}}</span>
              </li>
              <li class="ul-chidern3">
                <div class="ul-chidern3-conent">
                  <img :src="messages13[2].avatar" alt="">
                  <p>{{messages13[2].user_nicename}}</p>
                </div>
                <span>购买量：{{messages13[2].total}}</span>
              </li>
            </ul>
          </div>
          <div class="container-center">
            <ul>
              <li>排名</li>
              <li>头像</li>
              <li>主播名称</li>
              <li>购买量</li>
            </ul>
          </div>
          <div class="container-bottom">
            <ul>
              <li v-for="(item,index) in messages13.slice(3)" :key="item.ranking" >
                <span>{{index + 4}}</span>
                <span class="conainer-bottom-pic">
                  <img :src="item.avatar" alt='' />
                </span>
                <span>{{ item.user_nicename }}</span>
                <span>{{ item.total }}</span>
              </li>
            </ul>
          </div>
        </div>
        
      </van-tab>
      <van-tab title="第十六周">
        <div class="container-title">
          <img src="~@/assets/images/container-pic.png" alt="滑动"> 
          <span>左滑查看更多</span>
        </div>
        <div class="app-container">
          <img class="tab-pic" src="~@/assets/images/tab-pic.png" alt="直播人数榜">
          <div class="container-top">
            <ul>
              <li class="ul-chidern1">
                <div class="ul-chidern1-conent">
                  <img :src="messages14[1].avatar" alt="">
                  <p>{{messages14[1].user_nicename}}</p>
                </div>
                <span>购买量：{{messages14[1].total}}</span>
              </li>
              <li class="ul-chidern2">
                <div class="ul-chidern2-conent">
                  <img :src="messages14[0].avatar" alt="">
                  <p>{{messages14[0].user_nicename}}</p>
                </div>
                <span>购买量：{{messages14[0].total}}</span>
              </li>
              <li class="ul-chidern3">
                <div class="ul-chidern3-conent">
                  <img :src="messages14[2].avatar" alt="">
                  <p>{{messages14[2].user_nicename}}</p>
                </div>
                <span>购买量：{{messages14[2].total}}</span>
              </li>
            </ul>
          </div>
          <div class="container-center">
            <ul>
              <li>排名</li>
              <li>头像</li>
              <li>主播名称</li>
              <li>购买量</li>
            </ul>
          </div>
          <div class="container-bottom">
            <ul>
              <li v-for="(item,index) in messages14.slice(3)" :key="item.ranking" >
                <span>{{index + 4}}</span>
                <span class="conainer-bottom-pic">
                  <img :src="item.avatar" alt='' />
                </span>
                <span>{{ item.user_nicename }}</span>
                <span>{{ item.total }}</span>
              </li>
            </ul>
          </div>
        </div>
        
      </van-tab>
      <van-tab title="第十七周">
        <div class="container-title">
          <img src="~@/assets/images/container-pic.png" alt="滑动"> 
          <span>左滑查看更多</span>
        </div>
        <div class="app-container">
          <img class="tab-pic" src="~@/assets/images/tab-pic.png" alt="直播人数榜">
          <div class="container-top">
            <ul>
              <li class="ul-chidern1">
                <div class="ul-chidern1-conent">
                  <img :src="messages15[1].avatar" alt="">
                  <p>{{messages15[1].user_nicename}}</p>
                </div>
                <span>购买量：{{messages15[1].total}}</span>
              </li>
              <li class="ul-chidern2">
                <div class="ul-chidern2-conent">
                  <img :src="messages15[0].avatar" alt="">
                  <p>{{messages15[0].user_nicename}}</p>
                </div>
                <span>购买量：{{messages15[0].total}}</span>
              </li>
              <li class="ul-chidern3">
                <div class="ul-chidern3-conent">
                  <img :src="messages15[2].avatar" alt="">
                  <p>{{messages15[2].user_nicename}}</p>
                </div>
                <span>购买量：{{messages15[2].total}}</span>
              </li>
            </ul>
          </div>
          <div class="container-center">
            <ul>
              <li>排名</li>
              <li>头像</li>
              <li>主播名称</li>
              <li>购买量</li>
            </ul>
          </div>
          <div class="container-bottom">
            <ul>
              <li v-for="(item,index) in messages15.slice(3)" :key="item.ranking" >
                <span>{{index + 4}}</span>
                <span class="conainer-bottom-pic">
                  <img :src="item.avatar" alt='' />
                </span>
                <span>{{ item.user_nicename }}</span>
                <span>{{ item.total }}</span>
              </li>
            </ul>
          </div>
        </div>
        
      </van-tab>
      <van-tab title="第十八周">
        <div class="container-title">
          <img src="~@/assets/images/container-pic.png" alt="滑动"> 
          <span>左滑查看更多</span>
        </div>
        <div class="app-container">
          <img class="tab-pic" src="~@/assets/images/tab-pic.png" alt="直播人数榜">
          <div class="container-top">
            <ul>
              <li class="ul-chidern1">
                <div class="ul-chidern1-conent">
                  <img :src="messages16[1].avatar" alt="">
                  <p>{{messages16[1].user_nicename}}</p>
                </div>
                <span>购买量：{{messages16[1].total}}</span>
              </li>
              <li class="ul-chidern2">
                <div class="ul-chidern2-conent">
                  <img :src="messages16[0].avatar" alt="">
                  <p>{{messages16[0].user_nicename}}</p>
                </div>
                <span>购买量：{{messages16[0].total}}</span>
              </li>
              <li class="ul-chidern3">
                <div class="ul-chidern3-conent">
                  <img :src="messages16[2].avatar" alt="">
                  <p>{{messages16[2].user_nicename}}</p>
                </div>
                <span>购买量：{{messages16[2].total}}</span>
              </li>
            </ul>
          </div>
          <div class="container-center">
            <ul>
              <li>排名</li>
              <li>头像</li>
              <li>主播名称</li>
              <li>购买量</li>
            </ul>
          </div>
          <div class="container-bottom">
            <ul>
              <li v-for="(item,index) in messages16.slice(3)" :key="item.ranking" >
                <span>{{index + 4}}</span>
                <span class="conainer-bottom-pic">
                  <img :src="item.avatar" alt='' />
                </span>
                <span>{{ item.user_nicename }}</span>
                <span>{{ item.total }}</span>
              </li>
            </ul>
          </div>
        </div>
        
      </van-tab>
    </van-tabs>
  </div>
</template>
<script>


export default {
  name: 'PageHome',
  data() {
    return {
      // 第一周
      items: [ // 定义一个数组
      { id: 1, avatar:'https://qnlive.dgshwhcb.top/android_117431_20240929_215137_2735745.jpg', touid: '117431', user_nicename: '狗哥', total: 5034 },
          { id: 2, avatar:'https://qnlive.dgshwhcb.top/108281_IOS_20240624052642_cerFace.png',touid: '108281', user_nicename: '不知名🦋', total: 3020 },
          { id: 3,avatar:'https://qnlive.dgshwhcb.top/106417_IOS_20240930135514_cerFace.png', touid: '106471', user_nicename: 'AIU', total: 2452 },
          { id: 4,avatar:'https://qnlive.dgshwhcb.top/164847_IOS_20240817213919_cerFace.png', touid: '104847', user_nicename: '鹿可s', total: 1751 },
          { id: 5,avatar:'https://qnlive.dgshwhcb.top/133964_IOS_20240927025220_cerFace.png', touid: '133964', user_nicename: '艾琳175s', total: 1480 },
          { id: 6,avatar:'https://qnlive.dgshwhcb.top/107002_IOS_20240610201447_cerFace.png', touid: '107002', user_nicename: '红颜s', total: 880 },
          { id: 7,avatar:'https://qnlive.dgshwhcb.top/110963_IOS_20241004084652_cerFace.png', touid: '110963', user_nicename: '喵主', total: 871 },
          { id: 8,avatar:'https://qnlive.dgshwhcb.top/177274_IOS_20240820191713_cerFace.png', touid: '177274', user_nicename: '极品幼斯酱葱', total: 752 },
          { id: 9,avatar:'https://qnlive.dgshwhcb.top/194348_IOS_20240825154528_cerFace.png', touid: '194348', user_nicename: '玥樾🌙', total: 730 },
          { id: 10,avatar:'', touid: '135677', user_nicename: '04-情侣主', total: 672 }
      ],
      // 第二周
      messages :[
          { id: 1,avatar:'https://qnlive.dgshwhcb.top/106417_IOS_20240930135514_cerFace.png', touid: '106417', user_nicename: 'AIU', total: 5341 },
          { id: 2,avatar:'https://qnlive.dgshwhcb.top/android_117431_20240929_215137_2735745.jpg', touid: '117431', user_nicename: '狗哥', total: 5016 },
          { id: 3,avatar:'https://qnlive.dgshwhcb.top/108281_IOS_20240624052642_cerFace.png', touid: '108281', user_nicename: '不知名🦋', total: 3046 },
          { id: 4,avatar:'https://qnlive.dgshwhcb.top/107439_IOS_20240916003115_cerFace.png"', touid: '107439', user_nicename: '小软s', total: 2265 },
          { id: 5,avatar:'https://qnlive.dgshwhcb.top/107422_IOS_20240922191057_cerFace.png', touid: '107422', user_nicename: '甜一', total: 1380 },
          { id: 6,avatar:'https://qnlive.dgshwhcb.top/109679_IOS_20240727120823_cerFace.png', touid: '109679', user_nicename: '刘二狗素人开发', total: 851 },
          { id: 7,avatar:'https://qnlive.dgshwhcb.top/108263_IOS_20240914220929_cerFace.png', touid: '108263', user_nicename: '素人挖掘机', total: 741 },
          { id: 8,avatar:'https://qnlive.dgshwhcb.top/107043_IOS_20240610214830_cerFace.png', touid: '107043', user_nicename: '缈渺s', total: 734 },
          { id: 9,avatar:'https://qnlive.dgshwhcb.top/107047_IOS_20240923021125_cerFace.png', touid: '107047', user_nicename: '凉子s', total: 571 },
          { id: 10,avatar:'https://qnlive.dgshwhcb.top/133964_IOS_20240927025220_cerFace.png', touid: '133964', user_nicename: '艾琳175s', total: 520 }
      ],
      // 第三周
      messages1 :[
            { id: 1,avatar:'https://qnlive.dgshwhcb.top/106417_IOS_20240930135514_cerFace.png', touid: '106417', user_nicename: 'AIU', total: 5340 },
            { id: 2,avatar:'https://qnlive.dgshwhcb.top/108281_IOS_20240624052642_cerFace.png', touid: '108281', user_nicename: '不知名🦋', total: 5014 },
            { id: 3,avatar:'https://qnlive.dgshwhcb.top/171887_IOS_20241013012031_cerFace.png', touid: '171887', user_nicename: 'Qi7⃣️', total: 3041 },
            { id: 4,avatar:'https://qnlive.dgshwhcb.top/107439_IOS_20240916003115_cerFace.png', touid: '107439', user_nicename: '小软s', total: 2265 },
            { id: 5,avatar:'https://qnlive.dgshwhcb.top/android_117431_20240929_215137_2735745.jpg', touid: '117431', user_nicename: '狗哥', total: 1380 },
            { id: 6,avatar:'https://qnlive.dgshwhcb.top/109679_IOS_20240727120823_cerFace.png', touid: '109679', user_nicename: '刘二狗素人开发', total: 850 },
            { id: 7,avatar:'https://qnlive.dgshwhcb.top/108263_IOS_20240914220929_cerFace.png', touid: '108263', user_nicename: '素人挖掘机', total: 741 },
            { id: 8,avatar:'https://qnlive.dgshwhcb.top/107043_IOS_20240610214830_cerFace.png', touid: '107043', user_nicename: '缈渺s', total: 734 },
            { id: 9,avatar:'https://qnlive.dgshwhcb.top/107047_IOS_20240923021125_cerFace.png', touid: '107047', user_nicename: '凉子s', total: 571 },
            { id: 10,avatar:'https://qnlive.dgshwhcb.top/133964_IOS_20240927025220_cerFace.png', touid: '133964', user_nicename: '艾琳175s', total: 519 }
      ],
      // 第四周
      messages2 :[
            { id: 1,avatar:'https://qnlive.dgshwhcb.top/106417_IOS_20240930135514_cerFace.png', touid: '106417', user_nicename: 'AIU', total: 6053 },
            { id: 2,avatar:'https://qnlive.dgshwhcb.top/android_117431_20240929_215137_2735745.jpg', touid: '117431', user_nicename: '狗哥', total: 3425 },
            { id: 3,avatar:'https://qnlive.dgshwhcb.top/133964_IOS_20240927025220_cerFace.png', touid: '133964', user_nicename: '艾琳175_S', total: 2341 },
            { id: 4,avatar:'https://qnlive.dgshwhcb.top/108281_IOS_20240624052642_cerFace.png', touid: '108281', user_nicename: '不知名🦋', total: 1941 },
            { id: 5,avatar:'https://qnlive.dgshwhcb.top/135677_IOS_20241011220626_cerFace.png', touid: '135677', user_nicename: '04-新回放更新', total: 1761 },
            { id: 6,avatar:'https://qnlive.dgshwhcb.top/107043_IOS_20240610214830_cerFace.png', touid: '107043', user_nicename: '缈渺S (在线版)', total: 1382 },
            { id: 7,avatar:'https://qnlive.dgshwhcb.top/171887_IOS_20241013012031_cerFace.png', touid: '171887', user_nicename: 'Qi7⃣️', total: 1300 },
            { id: 8,avatar:'https://qnlive.dgshwhcb.top/136868_IOS_20241003024052_cerFace.png', touid: '136868', user_nicename: 'HB00后情侣s', total: 1217 },
            { id: 9,avatar:'https://qnlive.dgshwhcb.top/116261_IOS_20241005115314_cerFace.png', touid: '116261', user_nicename: '泡芙', total: 873 },
            { id: 10,avatar:'https://qnlive.dgshwhcb.top/106423_IOS_20241007055645_cerFace.png', touid: '106423', user_nicename: '欣妍s新回放更新', total: 690 }
      ],
      //第五周
      messages3 :[
      { id: 1,avatar:'https://qnlive.dgshwhcb.top/171887_IOS_20241013012031_cerFace.png', touid: '171887', user_nicename: 'Qi7⃣️', total: 3626 },
            { id: 2,avatar:'https://qnlive.dgshwhcb.top/106417_IOS_20240930135514_cerFace.png', touid: '106417', user_nicename: 'AIU', total: 3311 },
            { id: 3,avatar:'https://qnlive.dgshwhcb.top/164847_IOS_20240817213919_cerFace.png', touid: '164847', user_nicename: '鹿可s', total: 2580 },
            { id: 4,avatar:'https://qnlive.dgshwhcb.top/android_117431_20240929_215137_2735745.jpg', touid: '117431', user_nicename: '狗哥', total: 2535 },
            { id: 5,avatar:'https://qnlive.dgshwhcb.top/108281_IOS_20240624052642_cerFace.png', touid: '108281', user_nicename: '不知名🦋', total: 2250 },
            { id: 6,avatar:'https://qnlive.dgshwhcb.top/136868_IOS_20241003024052_cerFace.png', touid: '136868', user_nicename: 'HB00后情侣s', total: 1491 },
            { id: 7,avatar:'https://qnlive.dgshwhcb.top/108744_IOS_20240911131705_cerFace.png', touid: '108744', user_nicename: '长沙俱乐部甜音S', total: 1088 },
            { id: 8,avatar:'https://qnlive.dgshwhcb.top/177274_IOS_20240820191713_cerFace.png', touid: '177274', user_nicename: '极品幼斯酱葱', total: 722 },
            { id: 9,avatar:'https://qnlive.dgshwhcb.top/140426_IOS_20240814011558_cerFace.png', touid: '140426', user_nicename: '美丝强奸继子更新', total: 709 },
            { id: 10,avatar:'https://qnlive.dgshwhcb.top/124833_IOS_20240701185254_cerFace.png', touid: '124833', user_nicename: '反差楠熙s', total: 678 },
      ],
      //第六周
      messages4 :[
            { id: 1,avatar:'https://qnlive.dgshwhcb.top/116781_IOS_20241022235928_cerFace.png?imageView2/2/w/600/h/600', touid: '116781', user_nicename: '橙宝⚠️限时特价', total: 5224 },
            { id: 2,avatar:'https://qnlive.dgshwhcb.top/106417_IOS_20240930135514_cerFace.png', touid: '106417', user_nicename: 'AIU', total: 4806 },
            { id: 3,avatar:'https://qnlive.dgshwhcb.top/164847_IOS_20240817213919_cerFace.png', touid: '109439', user_nicename: '车模尹s限时优惠', total: 4242 },
            { id: 4,avatar:'https://qnlive.dgshwhcb.top/164847_IOS_20240817213919_cerFace.png', touid: '164847', user_nicename: '鹿可回放限时特惠', total: 3936 },
            { id: 5,avatar:'https://qnlive.dgshwhcb.top/171887_IOS_20241013012031_cerFace.png', touid: '171887', user_nicename: 'Qi7⃣️', total: 3861 },
            { id: 6,avatar:'https://qnlive.dgshwhcb.top/android_117431_20240929_215137_2735745.jpg', touid: '117431', user_nicename: '狗哥', total: 1963 },
            { id: 7,avatar:'https://qnlive.dgshwhcb.top/108263_IOS_20240914220929_cerFace.png', touid: '108263', user_nicename: '素人挖掘机', total: 600 },
            { id: 8,avatar:'https://qnlive.dgshwhcb.top/107047_IOS_20241024220913_cerFace.png?imageView2/2/w/600/h/600', touid: '107047', user_nicename: '凉子s回放更新', total: 573 },
            { id: 9,avatar:'https://qnlive.dgshwhcb.top/android_163540_20240803_163458_9945684.jpg', touid: '163540', user_nicename: '夏主', total: 471 },
            { id: 10,avatar:'https://qnlive.dgshwhcb.top/221601_IOS_20240926212922_cerFace.png', touid: '221601', user_nicename: '佳禾小妈(看回放', total: 439 },
      ],
      //第七周
      messages5 :[
            { id: 1,avatar:'https://qnlive.dgshwhcb.top/116781_IOS_20241022235928_cerFace.png?imageView2/2/w/600/h/600', touid: '116781', user_nicename: '橙宝⚠️限时特价', total: 7601 },
            { id: 2,avatar:'https://qnlive.dgshwhcb.top/108281_IOS_20240624052642_cerFace.png', touid: '108281', user_nicename: '不知名🦋', total: 4214 },
            { id: 3,avatar:'https://qnlive.dgshwhcb.top/106417_IOS_20240930135514_cerFace.png', touid: '106417', user_nicename: 'AIU', total: 4131 },
            { id: 4,avatar:'https://qnlive.dgshwhcb.top/133964_IOS_20240927025220_cerFace.png', touid: '133964', user_nicename: '艾琳175_S', total: 3412 },
            { id: 5,avatar:'https://qnlive.dgshwhcb.top/164847_IOS_20240817213919_cerFace.png', touid: '109439', user_nicename: '车模尹s限时优惠', total: 3050 },
            { id: 6,avatar:'https://qnlive.dgshwhcb.top/124833_IOS_20241104201158_cerFace.png?imageView2/2/w/600/h/600', touid: '124833', user_nicename: '反差楠熙s', total: 2359 },
            { id: 7,avatar:'https://qnlive.dgshwhcb.top/android_117431_20240929_215137_2735745.jpg', touid: '117431', user_nicename: '狗哥', total: 1334 },
            { id: 8,avatar:'https://qnlive.dgshwhcb.top/107942_IOS_20240612020256_cerFace.png', touid: '107942', user_nicename: '小x大王', total: 860 },
            { id: 9,avatar:'https://qnlive.dgshwhcb.top/107043_IOS_20240610214830_cerFace.png', touid: '107043', user_nicename: '缈渺s', total: 775 },
            { id: 10,avatar:'https://qnlive.dgshwhcb.top/194348_IOS_20240825154528_cerFace.png', touid: '194348', user_nicename: '玥樾🌙', total: 510 },
      ],
      messages6 :[
            { id: 1,avatar:'https://qnlive.dgshwhcb.top/116781_IOS_20241022235928_cerFace.png?imageView2/2/w/600/h/600', touid: '116781', user_nicename: '橙宝⚠️限时特价', total: 9192 },
            { id: 2,avatar:'https://qnlive.dgshwhcb.top/106417_IOS_20240930135514_cerFace.png', touid: '106417', user_nicename: 'AIU', total: 4034 },
            { id: 3,avatar:'https://qnlive.dgshwhcb.top/124833_IOS_20241104201158_cerFace.png?imageView2/2/w/600/h/600', touid: '124833', user_nicename: '反差楠熙s', total: 2811 },
            { id: 4,avatar:'https://qnlive.dgshwhcb.top/android_117431_20240929_215137_2735745.jpg', touid: '117431', user_nicename: '狗哥', total: 2540 },
            { id: 5,avatar:'https://qnlive.dgshwhcb.top/108281_IOS_20241107050113_cerFace.png?imageView2/2/w/600/h/600', touid: '108281', user_nicename: '不知名🦋', total: 2150 },
            { id: 6,avatar:'https://qnlive.dgshwhcb.top/159156_IOS_20240811143319_cerFace.png', touid: '159156', user_nicename: '玉兔S', total: 1279 },
            { id: 7,avatar:'https://qnlive.dgshwhcb.top/107942_IOS_20240612020256_cerFace.png', touid: '107942', user_nicename: '小x大王', total: 1192 },
            { id: 8,avatar:'https://qnlive.dgshwhcb.top/164847_IOS_20240817213919_cerFace.png', touid: '104847', user_nicename: '鹿可s', total: 984 },
            { id: 9,avatar:'https://qnlive.dgshwhcb.top/106546_IOS_20240624120149_cerFace.png', touid: '106546', user_nicename: '伍玥🌙回放特惠', total: 675 },
            { id: 10,avatar:'https://qnlive.dgshwhcb.top/135677_IOS_20241011220626_cerFace.png', touid: '135677', user_nicename: '04-情侣主', total: 660 },
      ],
      messages7 :[
            { id: 1,avatar:'https://qnlive.dgshwhcb.top/107043_IOS_20240610214830_cerFace.png', touid: '107043', user_nicename: '缈渺S (特价版)', total: 7978 },
            { id: 2,avatar:'https://qnlive.dgshwhcb.top/108281_IOS_20241112013155_cerFace.png?imageView2/2/w/600/h/600', touid: '108281', user_nicename: '不知名🦋', total: 6713 },
            { id: 3,avatar:'https://qnlive.dgshwhcb.top/106417_IOS_20240930135514_cerFace.png', touid: '106417', user_nicename: 'AIU', total: 4774 },
            { id: 4,avatar:'https://qnlive.dgshwhcb.top/107002_IOS_20241116115921_cerFace.png?imageView2/2/w/600/h/600', touid: '107002', user_nicename: '红颜S', total: 3266 },
            { id: 5,avatar:'https://qnlive.dgshwhcb.top/android_117431_20240929_215137_2735745.jpg', touid: '117431', user_nicename: '狗哥', total: 2942 },
            { id: 6,avatar:'https://qnlive.dgshwhcb.top/124833_IOS_20241117020916_cerFace.png?imageView2/2/w/600/h/600', touid: '124833', user_nicename: '反差楠熙s👑', total: 2067 },
            { id: 7,avatar:'https://qnlive.dgshwhcb.top/159156_IOS_20240811143319_cerFace.png', touid: '159156', user_nicename: '玉兔S', total: 1453 },
            { id: 8,avatar:'', touid: '104847', user_nicename: '', total:  ""},
            { id: 9,avatar:'', touid: '106546', user_nicename: '', total: "" },
            { id: 10,avatar:'', touid: '135677', user_nicename: '', total: "" },
      ],
      messages8 :[
            { id: 1,avatar:'https://qnlive.dgshwhcb.top/107002_IOS_20241116115921_cerFace.png?imageView2/2/w/600/h/600', touid: '107002', user_nicename: '红颜S', total: 6068 },
            { id: 2,avatar:'https://qnlive.dgshwhcb.top/106417_IOS_20240930135514_cerFace.png', touid: '106417', user_nicename: 'AIU', total: 3320 },
            { id: 3,avatar:'https://qnlive.dgshwhcb.top/108281_IOS_20241119131848_cerFace.png?imageView2/2/w/600/h/600', touid: '108281', user_nicename: '不知名🦋', total: 3158 },
            { id: 4,avatar:'https://qnlive.dgshwhcb.top/107001_IOS_20240801175724_cerFace.png', touid: '107001', user_nicename: 'UUs', total: 2020 },
            { id: 5,avatar:'https://qnlive.dgshwhcb.top/107002_IOS_20241116115921_cerFace.png?imageView2/2/w/600/h/600', touid: '171887', user_nicename: 'QI7', total: 1613 },
            { id: 6,avatar:'https://qnlive.dgshwhcb.top/159156_IOS_20240811143319_cerFace.png', touid: '159156', user_nicename: '玉兔S', total: 1195 },
            { id: 7,avatar:'https://qnlive.dgshwhcb.top/108263_IOS_20240914220929_cerFace.png', touid: '108263', user_nicename: '素人挖掘机	', total: 958 },
            { id: 8,avatar:'https://qnlive.dgshwhcb.top/107942_IOS_20240612020256_cerFace.png', touid: '107942', user_nicename: '小x大王	', total:  768},
            { id: 9,avatar:'https://qnlive.dgshwhcb.top/android_117431_20240929_215137_2735745.jpg', touid: '117431', user_nicename: '狗哥', total: 621 },
            { id: 10,avatar:'https://qnlive.dgshwhcb.top/107428_IOS_20241031120108_cerFace.png?imageView2/2/w/600/h/600', touid: '107428', user_nicename: '香芋朱朱	', total: 531 },
      ],
      messages9 :[
            { id: 1,avatar:'https://qnlive.dgshwhcb.top/108281_IOS_20241119131848_cerFace.png?imageView2/2/w/600/h/600', touid: '108281', user_nicename: '不知名🦋', total: 3202 },
            { id: 2,avatar:'https://qnlive.dgshwhcb.top/106417_IOS_20240930135514_cerFace.png', touid: '106417', user_nicename: 'AIU', total: 1893 },
            { id: 3,avatar:'https://qnlive.dgshwhcb.top/171887_IOS_20241013012031_cerFace.png', touid: '171887', user_nicename: 'QI7回放降价', total: 1815 },
            { id: 4,avatar:'https://qnlive.dgshwhcb.top/133964_IOS_20240927025220_cerFace.png', touid: '133964', user_nicename: '艾琳175_S', total: 1720 },
            { id: 5,avatar:'https://qnlive.dgshwhcb.top/android_117431_20240929_215137_2735745.jpg', touid: '117431', user_nicename: '狗哥', total: 1317 },
            { id: 6,avatar:'https://qnlive.dgshwhcb.top/135677_IOS_20241113142455_cerFace.png?imageView2/2/w/600/h/600', touid: '135677', user_nicename: '04-情侣主', total: 1129 },
            { id: 7,avatar:'https://qnlive.dgshwhcb.top/107002_IOS_20241116115921_cerFace.png?imageView2/2/w/600/h/600', touid: '107002', user_nicename: '红颜S', total: 1058 },
            { id: 8,avatar:'https://qnlive.dgshwhcb.top/116781_IOS_20241112083028_cerFace.png?imageView2/2/w/600/h/600', touid: '116781', user_nicename: '橙宝s s s', total:  951},
            { id: 9,avatar:'https://qnlive.dgshwhcb.top/288449_IOS_20241127013758_cerFace.png?imageView2/2/w/600/h/600', touid: '288449', user_nicename: '茹雪S', total: 835 },
            { id: 10,avatar:'https://qnlive.dgshwhcb.top/android_181044_20241106_164348_3545836.jpg?imageView2/2/w/600/h/600', touid: '181044', user_nicename: '栀子女王s广州', total: 754 },
      ],
      messages10 :[
            { id: 1,avatar:'https://qnlive.dgshwhcb.top/133964_IOS_20240927025220_cerFace.png', touid: '133964', user_nicename: '艾琳175_S', total: 3151 },
            { id: 2,avatar:'https://qnlive.dgshwhcb.top/android_131324_20240712_003022_0495730.jpg', touid: '131324', user_nicename: '一茶s', total: 2257 },
            { id: 3,avatar:'https://qnlive.dgshwhcb.top/106417_IOS_20240930135514_cerFace.png', touid: '106417', user_nicename: 'AIU', total: 1830 },
            { id: 4,avatar:'https://qnlive.dgshwhcb.top/107002_IOS_20241116115921_cerFace.png?imageView2/2/w/600/h/600', touid: '107002', user_nicename: '红颜S', total: 1816 },
            { id: 5,avatar:'https://qnlive.dgshwhcb.top/android_117431_20240929_215137_2735745.jpg', touid: '117431', user_nicename: '狗哥', total: 1462 },
            { id: 6,avatar:'https://qnlive.dgshwhcb.top/107942_IOS_20240612020256_cerFace.png', touid: '107942', user_nicename: '小x大王', total: 1297 },
            { id: 7,avatar:'https://qnlive.dgshwhcb.top/159156_IOS_20240811143319_cerFace.png', touid: '107002', user_nicename: '玉兔S', total: 891 },
            { id: 8,avatar:'https://qnlive.dgshwhcb.top/288449_IOS_20241127013758_cerFace.png?imageView2/2/w/600/h/600', touid: '116781', user_nicename: '茹雪S', total:  838},
            { id: 9,avatar:'https://qnlive.dgshwhcb.top/163453_IOS_20240801165343_cerFace.png', touid: '163453', user_nicename: '晚晚🐰S', total: 770 },
            { id: 10,avatar:'https://qnlive.dgshwhcb.top/171887_IOS_20241013012031_cerFace.png', touid: '171887', user_nicename: 'QI7️⃣', total: 621 },
      ],
      messages11 :[
            { id: 1,avatar:'https://qnlive.dgshwhcb.top/android_117431_20241218_003703_2391317.jpg?imageView2/2/w/600/h/600', touid: '133964', user_nicename: '狗哥', total: 3995 },
            { id: 2,avatar:'https://qnlive.dgshwhcb.top/106417_IOS_20240930135514_cerFace.png', touid: '131324', user_nicename: 'AIU', total: 3075 },
            { id: 3,avatar:'https://qnlive.dgshwhcb.top/108281_IOS_20241213162336_cerFace.png?imageView2/2/w/600/h/600', touid: '106417', user_nicename: '不知名🦋', total: 2325 },
            { id: 4,avatar:'https://qnlive.dgshwhcb.top/107002_IOS_20241116115921_cerFace.png?imageView2/2/w/600/h/600', touid: '107002', user_nicename: '红颜S', total: 1998 },
            { id: 5,avatar:'https://qnlive.dgshwhcb.top/131324_IOS_20241210134734_cerFace.png?imageView2/2/w/600/h/600', touid: '117431', user_nicename: '一茶s', total: 1374 },
            { id: 6,avatar:'https://qnlive.dgshwhcb.top/135677_IOS_20241215183837_cerFace.png?imageView2/2/w/600/h/600', touid: '107942', user_nicename: '04-回放666', total: 1151 },
            { id: 7,avatar:'https://qnlive.dgshwhcb.top/107043_IOS_20240610214830_cerFace.png', touid: '107002', user_nicename: '缈緲s', total: 1048 },
            { id: 8,avatar:'https://qnlive.dgshwhcb.top/124833_IOS_20241117020916_cerFace.png?imageView2/2/w/600/h/600', touid: '116781', user_nicename: '反差楠熙s👑', total:  947},
            { id: 9,avatar:'https://qnlive.dgshwhcb.top/274883_IOS_20241215141722_cerFace.png?imageView2/2/w/600/h/600', touid: '163453', user_nicename: '七月大大', total: 901 },
            { id: 10,avatar:'https://qnlive.dgshwhcb.top/108263_IOS_20240914220929_cerFace.png', touid: '171887', user_nicename: '素人挖掘机', total: 830 },
      ],
      messages12 :[
            { id: 1,avatar:'https://qnlive.dgshwhcb.top/108281_IOS_20241223003249_cerFace.png?imageView2/2/w/600/h/600', touid: '133964', user_nicename: '不知名🦋', total: 10046 },
            { id: 2,avatar:'https://qnlive.dgshwhcb.top/131324_IOS_20241223024529_cerFace.png?imageView2/2/w/600/h/600', touid: '131324', user_nicename: '一茶s', total: 9649 },
            { id: 3,avatar:'https://qnlive.dgshwhcb.top/124833_IOS_20241222205228_cerFace.png?imageView2/2/w/600/h/600', touid: '106417', user_nicename: '反差楠熙s👑', total: 5519 },
            { id: 4,avatar:'https://qnlive.dgshwhcb.top/106417_IOS_20241223022154_cerFace.png?imageView2/2/w/600/h/600', touid: '107002', user_nicename: 'AIU', total: 3810 },
            { id: 5,avatar:'https://qnlive.dgshwhcb.top/android_117431_20241220_002510_85828.jpg?imageView2/2/w/600/h/600', touid: '117431', user_nicename: '狗哥', total: 3754 },
            { id: 6,avatar:'https://qnlive.dgshwhcb.top/133964_IOS_20240927025220_cerFace.png', touid: '107942', user_nicename: '艾琳175_S', total: 1534 },
            { id: 7,avatar:'https://qnlive.dgshwhcb.top/107002_IOS_20241116115921_cerFace.png?imageView2/2/w/600/h/600', touid: '107002', user_nicename: '红颜S', total: 1513 },
            { id: 8,avatar:'https://qnlive.dgshwhcb.top/159156_IOS_20240811143319_cerFace.png', touid: '116781', user_nicename: '玉兔S', total:  1260},
            { id: 9,avatar:'https://qnlive.dgshwhcb.top/107942_IOS_20241214024330_cerFace.png?imageView2/2/w/600/h/600', touid: '163453', user_nicename: '小x大王', total: 853 },
            { id: 10,avatar:'https://qnlive.dgshwhcb.top/293077_IOS_20241203201617_cerFace.png?imageView2/2/w/600/h/600', touid: '171887', user_nicename: '伽萝_S', total: 800 },
      ],
      messages13 :[
            { id: 1,avatar:'https://qnlive.dgshwhcb.top/124833_IOS_20241222205228_cerFace.png?imageView2/2/w/600/h/600', touid: '133964', user_nicename: '反差楠熙s👑', total: 6026 },
            { id: 2,avatar:'https://qnlive.dgshwhcb.top/107043_IOS_20240610214830_cerFace.png', touid: '131324', user_nicename: '缈缈S（在线版）', total: 5819 },
            { id: 3,avatar:'https://qnlive.dgshwhcb.top/133964_IOS_20240927025220_cerFace.png', touid: '106417', user_nicename: '艾琳175_S', total: 3493 },
            { id: 4,avatar:'https://qnlive.dgshwhcb.top/131324_IOS_20241223024529_cerFace.png?imageView2/2/w/600/h/600', touid: '107002', user_nicename: '一茶s', total: 2622 },
            { id: 5,avatar:'https://qnlive.dgshwhcb.top/135677_IOS_20241215183837_cerFace.png?imageView2/2/w/600/h/600', touid: '117431', user_nicename: '04-本周猛播版', total: 2358 },
            { id: 6,avatar:'https://qnlive.dgshwhcb.top/164847_IOS_20241224102114_cerFace.png?imageView2/2/w/600/h/600', touid: '107942', user_nicename: '鹿可回放限时特惠', total: 2088 },
            { id: 7,avatar:'https://qnlive.dgshwhcb.top/106417_IOS_20241223022154_cerFace.png?imageView2/2/w/600/h/600', touid: '107002', user_nicename: 'AIU', total: 1810 },
            { id: 8,avatar:'https://qnlive.dgshwhcb.top/107002_IOS_20241116115921_cerFace.png?imageView2/2/w/600/h/600', touid: '116781', user_nicename: '红颜S', total:  1211},
            { id: 9,avatar:'https://qnlive.dgshwhcb.top/293077_IOS_20241203201617_cerFace.png?imageView2/2/w/600/h/600', touid: '163453', user_nicename: '伽萝_S', total: 817 },
            { id: 10,avatar:'https://qnlive.dgshwhcb.top/258359_IOS_20241111231317_cerFace.png?imageView2/2/w/600/h/600', touid: '171887', user_nicename: '白雪 -S💛', total: 803 },
      ],
      messages14 :[
            { id: 1,avatar:'https://qnlive.dgshwhcb.top/131324_IOS_20241223024529_cerFace.png?imageView2/2/w/600/h/600', touid: '133964', user_nicename: '一茶s', total: 4060 },
            { id: 2,avatar:'https://qnlive.dgshwhcb.top/106417_IOS_20241223022154_cerFace.png?imageView2/2/w/600/h/600', touid: '131324', user_nicename: 'AIU', total: 2648 },
            { id: 3,avatar:'https://qnlive.dgshwhcb.top/150087_IOS_20240720200737_cerFace.png', touid: '106417', user_nicename: '南京小小s', total: 2633 },
            { id: 4,avatar:'https://qnlive.dgshwhcb.top/133964_IOS_20240927025220_cerFace.png', touid: '107002', user_nicename: '艾琳175_S', total: 2255 },
            { id: 5,avatar:'https://qnlive.dgshwhcb.top/107002_IOS_20241116115921_cerFace.png?imageView2/2/w/600/h/600', touid: '117431', user_nicename: '红颜S', total: 1916 },
            { id: 6,avatar:'https://qnlive.dgshwhcb.top/262312_IOS_20241110235041_cerFace.png?imageView2/2/w/600/h/600', touid: '107942', user_nicename: '泡芙s', total: 1728 },
            { id: 7,avatar:'https://qnlive.dgshwhcb.top/164847_IOS_20250101230103_cerFace.png?imageView2/2/w/600/h/600', touid: '107002', user_nicename: '鹿可s', total: 776 },
            { id: 8,avatar:'https://qnlive.dgshwhcb.top/android_117431_20241220_002510_85828.jpg?imageView2/2/w/600/h/600', touid: '116781', user_nicename: '狗哥', total:  731},
            { id: 9,avatar:'https://qnlive.dgshwhcb.top/117291_IOS_20241231195942_cerFace.png?imageView2/2/w/600/h/600', touid: '163453', user_nicename: '酒鸽sama', total: 726 },
            { id: 10,avatar:'https://qnlive.dgshwhcb.top/258359_IOS_20241111231317_cerFace.png?imageView2/2/w/600/h/600', touid: '171887', user_nicename: '白雪 -S💛', total: 594 },
      ],
      messages15 :[
            { id: 1,avatar:'https://qnlive.dgshwhcb.top/131324_IOS_20241223024529_cerFace.png?imageView2/2/w/600/h/600', touid: '133964', user_nicename: '一茶s', total: 6842 },
            { id: 2,avatar:'https://qnlive.dgshwhcb.top/150087_IOS_20240720200737_cerFace.png', touid: '131324', user_nicename: '南京小小s', total: 3516 },
            { id: 3,avatar:'https://qnlive.dgshwhcb.top/124833_IOS_20241222205228_cerFace.png?imageView2/2/w/600/h/600', touid: '106417', user_nicename: '反差楠熙s👑', total: 3306 },
            { id: 4,avatar:'https://qnlive.dgshwhcb.top/107001_IOS_20241218214522_cerFace.png?imageView2/2/w/600/h/600', touid: '107002', user_nicename: 'UUs💭', total: 2522 },
            { id: 5,avatar:'https://qnlive.dgshwhcb.top/262312_IOS_20250106150119_cerFace.png?imageView2/2/w/600/h/600', touid: '117431', user_nicename: '泡芙s', total: 2046 },
            { id: 6,avatar:'https://qnlive.dgshwhcb.top/107002_IOS_20241116115921_cerFace.png?imageView2/2/w/600/h/600', touid: '107942', user_nicename: '红颜S', total: 1669 },
            { id: 7,avatar:'https://qnlive.dgshwhcb.top/106417_IOS_20241223022154_cerFace.png?imageView2/2/w/600/h/600', touid: '107002', user_nicename: 'AIU', total: 1572 },
            { id: 8,avatar:'https://qnlive.dgshwhcb.top/108281_IOS_20241223003249_cerFace.png?imageView2/2/w/600/h/600', touid: '116781', user_nicename: '不知名🦋', total:  1556},
            { id: 9,avatar:'https://qnlive.dgshwhcb.top/117291_IOS_20241231195942_cerFace.png?imageView2/2/w/600/h/600', touid: '163453', user_nicename: '酒鸽sama', total: 1302 },
            { id: 10,avatar:'https://qnlive.dgshwhcb.top/android_117431_20241220_002510_85828.jpg?imageView2/2/w/600/h/600', touid: '171887', user_nicename: '狗哥', total: 1255 },
      ],
      messages16 :[
            { id: 1,avatar:'https://qnlive.dgshwhcb.top/131324_IOS_20241223024529_cerFace.png?imageView2/2/w/600/h/600', touid: '133964', user_nicename: '一茶s', total: 5301 },
            { id: 2,avatar:'https://qnlive.dgshwhcb.top/108281_IOS_20241223003249_cerFace.png?imageView2/2/w/600/h/600', touid: '131324', user_nicename: '不知名🦋', total: 3448 },
            { id: 3,avatar:'https://qnlive.dgshwhcb.top/android_117431_20241220_002510_85828.jpg?imageView2/2/w/600/h/600', touid: '106417', user_nicename: '狗哥', total: 2731 },
            { id: 4,avatar:'https://qnlive.dgshwhcb.top/262312_IOS_20250106150119_cerFace.png?imageView2/2/w/600/h/600', touid: '107002', user_nicename: '泡芙s', total: 2344 },
            { id: 5,avatar:'https://qnlive.dgshwhcb.top/106417_IOS_20241223022154_cerFace.png?imageView2/2/w/600/h/600', touid: '117431', user_nicename: 'AIU', total: 1889 },
            { id: 6,avatar:'https://qnlive.dgshwhcb.top/107002_IOS_20241116115921_cerFace.png?imageView2/2/w/600/h/600', touid: '107942', user_nicename: '红颜S', total: 1782 },
            { id: 7,avatar:'https://qnlive.dgshwhcb.top/164847_IOS_20250101230103_cerFace.png?imageView2/2/w/600/h/600', touid: '107002', user_nicename: '鹿可s', total: 1408 },
            { id: 8,avatar:'https://qnlive.dgshwhcb.top/124833_IOS_20241222205228_cerFace.png?imageView2/2/w/600/h/600', touid: '116781', user_nicename: '反差楠熙s👑', total:  1161},
            { id: 9,avatar:'https://qnlive.dgshwhcb.top/150087_IOS_20240720200737_cerFace.png', touid: '163453', user_nicename: '南京小小s', total: 858 },
            { id: 10,avatar:'https://qnlive.dgshwhcb.top/133671_IOS_20240714080514_cerFace.png', touid: '171887', user_nicename: '黛樱(回放特惠)', total: 808 },
      ],
    }
  },
  created() {
    this.fetchData(); // 组件创建时调用 fetchData 方法
  },
  methods: {
    // fetchData() {
    //   fetch('https://api.example.com/items') // 替换为你的实际 API URL
    //     .then(response => {
    //       if (!response.ok) {
    //         throw new Error('网络响应不是 OK');
    //       }
    //       return response.json();
    //     })
    //     .then(data => {
    //       this.items = data; // 将获取到的新数据赋值给 items
    //     })
    //     .catch(error => {
    //       console.error('发生错误:', error);
    //     });
    // },
    back() {
      this.$router.push('/'); // 返回首页
    },
  }
}
</script>


<style lang="less" scoped>
.about {
  position: relative;
  // font-size: 14px;
  // padding-top: 246px;
  // background: url('~@/assets/images/about-bg.jpg') no-repeat top left / 100%;
  // margin-bottom: 50px;
  /deep/ .van-tabs__line {
    top: 28px;
    padding-bottom: 0 !important;
    background-color: #fff;
  }
  /deep/ .van-tabs{
    &__wrap{
      z-index: 20;
      display: flex;
      align-items: center;
      &::before{
        content: '';
        display: inline-block;
        width: 54px;
        height: 54px;
        margin-top: 10px;
        margin-left: 6px;
        background: url('~@/assets/images/zb.png') no-repeat;
        background-size: 100%;
      }
      .van-tab{
        font-size: 12px;
        color: #fff;
        background: linear-gradient(to bottom, #e06e53, #bc2212);
      }
    }

  }
  /deep/ .van-tabs__nav--line {
    height: 32px;
    margin-left: 10px;
    padding-bottom: 0 !important;
  }
  /deep/ .van-tabs--line .van-tabs__wrap{
    margin: 10px 0;
    border-radius: 10px;
  }
  /deep/ .van-tabs__nav--line.van-tabs__nav--complete {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .about-container {
    height: 260;
    background: url('~@/assets/images/bg.png') no-repeat top left / 100%;
  }
  .container-title{
    color: #fff;
    font-size: 10px;
    padding-right: 10px;
    display: flex;
    justify-content: flex-end;
  }
  .container-title img{
    width: 14px;
    height: 18px;
    margin-right: 5px;
  }
  .container-title span{
    line-height: 24px;
  }
  .tab-pic{
    width: 180px;
    // height: 36px;
    position: absolute;
    top: -20px;
    left: 80px;
  }
  .app-container{
    position: relative;
    width: 341px;
    margin: 4px auto;
    height: 534px;
    background: url('~@/assets/images/content1.png') no-repeat top left / 100%;
  }
  .container-top{
    ul{
      display: flex;
      justify-content: space-around;
      li{
        margin-top: 40px;
      }
      .ul-chidern1{
        position: relative;
        margin-top: 60px;
        width:106px;
        height:132px;
        background: url('~@/assets/images/top2.png')no-repeat top left / 100%;
        margin-left: 6px;
        .ul-chidern1-conent{
          width: 50px;
          height: 50px;
          border-radius: 100%;
          border:1px solid #fff;
          margin-left: 22px;
          margin-top: 10px;
          img {
            width: 100%;
            height: 100%;
            border-radius: 100%;
          }
          p{
            font-size: 12px;
            text-align: center;
          }
        }
          span{
          display: block;
          width: 100%;
          text-align: left;
          color: #fff;
          font-size: 10px;
          margin-top: 52px;
          margin-left: 6px;
          color: #550e00;
        }
        .ul-chidern1-conent::after{
            content: '';
            display: block;
            width: 25px;
            height: 25px;
            background: url('~@/assets/images/tab2.png')no-repeat top left / 100%;
            position: absolute;
            top: -2px;
            left: 19px;
          }
      }
      .ul-chidern2{
        position: relative;
        margin-top: 40px;
        width:106px;
        height:144px;
        background: url('~@/assets/images/top1.png')no-repeat top left / 100%;
        margin-left: 6px;
        .ul-chidern2-conent{
          width: 54px;
          height: 54px;
          border-radius: 100%;
          border:1px solid #fff;
          margin-left: 24px;
          margin-top: 15px;
          img {
            width: 100%;
            height: 100%;
            border-radius: 100%;
          }
          p{
            font-size: 12px;
            text-align: center;
          }
        }
        span{
          display: block;
          width: 100%;
          text-align: left;
          color: #fff;
          font-size: 10px;
          margin-top: 63px;
          margin-left: 6px;
          color: #550e00;
        }
        .ul-chidern2-conent::after{
            content: '';
            display: block;
            width: 30px;
            height: 30px;
            background: url('~@/assets/images/tab1.png')no-repeat top left / 100%;
            position: absolute;
            top: 1px;
            left: 22px;
          }
      }
      .ul-chidern3{
        margin-right: 8px;
        margin-top: 60px;
        width:105px;
        height:138px;
        background: url('~@/assets/images/top3.png')no-repeat top left / 100%;
        margin-left: 6px;
        .ul-chidern3-conent{
          position: relative;
          width: 50px;
          height: 50px;
          border-radius: 100%;
          border:1px solid #fff;
          margin-left: 24px;
          margin-top: 10px;
          img {
            width: 100%;
            height: 100%;
            border-radius: 100%;
          }
          p{
            font-size: 12px;
            text-align: center;
          }
        }
        span{
          display: block;
          width: 100%;
          text-align: left;
          color: #fff;
          font-size: 10px;
          margin-top: 51px;
          margin-left: 6px;
          color: #550e00;
        }
        .ul-chidern3-conent::after{
            content: '';
            display: block;
            width: 25px;
            height: 25px;
            background: url('~@/assets/images/tab3.png')no-repeat top left / 100%;
            position: absolute;
            top: -12px;
            left: -6px;
          }
      }
    }
    }
  .container-center{
    color: #b04400;
    font-weight: 600;
    margin-top:18px;
    font-size: 16px ;
    ul{
      margin-left: 10px;
      width: 320px;
      display: flex;
      justify-content: space-around;
      padding-bottom: 10px;
      border-bottom: 1px dashed #b04400;
      li{
        display: flex;
        justify-items: center;
        margin-left: -14px;
      }

    }
  }
  .container-bottom{
    ul{
      overflow-y: auto;
      margin-left: 8px;
      margin-top: 10px;
      width: 324px;
      height: 290px;
      li {
        font-size: 13px;
        padding: 2px 0;
        span:nth-child(1) {
          text-align: center;
          width: 26px;
          display: inline-block;
        }
        span:nth-child(2) {
          display: inline-block;
          text-align: center;
          width: 90px;
          border-radius: 100%;
          img {
            width: 30px;
            height: 30px;
            border-radius: 100%;
            text-align: center;
          }
        }
        span:nth-child(3) {
          text-align: center;
          display: inline-block;
          width: 120px;
          padding-right: 12px;
          white-space: nowrap;        /* 不换行 */
          overflow: hidden;           /* 隐藏溢出的内容 */
          text-overflow: ellipsis;
        }
        span:nth-child(4) {
          width: 60px;
          display: inline-block;
          text-align: left;
          white-space: nowrap;        /* 不换行 */
          overflow: hidden;           /* 隐藏溢出的内容 */
          text-overflow: ellipsis;
        }
      }
      li:nth-child(even) {
        height: 32px;
        width: 100%; /* 偶数项背景色 */
        background-color: #f9dbbe;
      }
      li:nth-child(odd) {
        height: 34px;
        width: 100%;
 /* 偶数项背景色 */
      }
    }
  }
}
.back{
  position: absolute;
  top: -244px;
  left: 10px;
}
</style>